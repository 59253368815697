import './scss/blocks/iko-notification-box-block.scss';

document.addEventListener('DOMContentLoaded', e => {
  const notificationBox = document.querySelectorAll('.iko-notification-box');
  notificationBox.forEach(box => {
    const notificationCloseButton = box.querySelector('div>button');
    const websiteSettings = JSON.parse(sessionStorage.getItem('iko-website-settings'));
    const recurringUser = websiteSettings ? websiteSettings.hidNotificationBox : false;

    if (!recurringUser) {
      box.style.display = 'flex';
      notificationCloseButton.addEventListener('click', e => {
        box.style.display = 'none';
        sessionStorage.setItem('iko-website-settings', JSON.stringify({ hidNotificationBox: true }));
      });
    }
  });
});